<template>
  <div class="student-home">
    <!-- 반응형 헤더 이미지 -->
    <div class="header-image">
      <img src="@/assets/images/mypage_header.jpg" alt="마이페이지 헤더 이미지" />
    </div>

    <!-- 프로필 아이콘 및 사용자 정보 -->
    <div class="profile-section">
      <img class="profile-icon" src="@/assets/images/userIcon.jpg" alt="프로필 아이콘" />
      <h2 class="username">{{ name }}</h2>
      <p class="userrole">교육생님</p>
    </div>

    <!-- 사용자 정보 -->    
      <div class="info-item">
        <p class="info-label"><i class="fas fa-certificate"></i> 자격증 종류</p>
        <p class="info-value large-text">{{ certType }}종</p>
      </div>    
      
    <!-- </div> -->
      <div class="info-item">
        <p class="info-label"><i class="fas fa-calendar-alt"></i> 교육 시작일</p>
        <p class="info-value large-text">{{ startDate }}</p>
      </div>

    <div class="progress-container">
    <p class="info-label"><i class="fas fa-clock"></i> 비행 교육 진행 현황</p>

    <div class="progress-item">
        <p class="progress-label">비행교육 <span>{{ totalHours }}/{{ requiredHours }}</span></p>
        <div class="progress-bar">
          <div class="progress-fill" :style="{ width: progressRate + '%' }"></div>
        </div>
        <p class="progress-rate">달성률 {{ progressRate }}%</p>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      name: "",
      startDate:'',
      certType: null,
      totalHours: 0,
      requiredHours: 0,      
      progressRate:null,
      userId: sessionStorage.getItem("userId"),
    };
  },
  mounted() {
    if (this.userId) {
      this.getUserInfo();
      this.getIssueUserInfo();
    } else {
      console.error("로그인한 사용자 ID를 찾을 수 없습니다.");
    }
  },
  methods: {
    async getUserInfo() {
      try {
        const response = await axios.get(`/users/findById/${this.userId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("token"))}`,
          },
        });

        const userData = response.data;
        this.certType = userData.cert_type;
        this.name = userData.name;
        this.startDate = this.formattedDate(userData.attendanceTime);
        
        //this.totalHours= userData.total_hours;
      } catch (error) {
        console.error("사용자 정보 가져오기 실패:", error);
      }
    },

    formattedDate(ddate) {
      return new Date(ddate).toLocaleDateString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }).replace(/\.$/, "");
    },

    async getIssueUserInfo() {
      try {
        const response = await axios.get(`/certificate/create?id=${this.userId}`, {
          headers: { "Content-Type": "application/json" },
        });

        if (response.data.length > 0) {
          let totalFlightHours = response.data.reduce((sum, record) => {
            return sum + parseFloat(record.solo_time || 0) + parseFloat(record.training_time || 0) + parseFloat(record.trainer_time || 0);
          }, 0);

          this.totalHours = totalFlightHours;
          
          this.setRequiredHours();
        }
        else { 
          
          this.setRequiredHours();
        }
      } catch (error) {
        console.error("이수시간 가져오기 실패:", error);
      }
    },

    setRequiredHours() {
      this.requiredHours = this.certType === 1 ? 20 : this.certType === 2 ? 10 : this.certType === 3 ? 6 : 0;
      console.log("this.requiredHours:"+this.requiredHours);
      this.calculateProgress();
    },

    calculateProgress() {
      this.progressRate = this.requiredHours > 0 ? ((this.totalHours / this.requiredHours) * 100).toFixed(1) : 0;
      this.totalHours = parseFloat(this.totalHours).toFixed(1)
    },
  },
};
</script>

<style scoped>
/* 기본 컨테이너 */
.student-home {
  text-align: center;
  /* padding: 20px; */
}
/* 반응형 헤더 이미지 */
.header-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

/* 프로필 아이콘 및 사용자 정보 */
.profile-section {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-icon {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 3px solid #ddd;
  background-color: white;
}

.username {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 0px;
}

.userrole {
  font-size: 32px;
  font-weight: bold;
 
}

.info-item {

  text-align: center;
  margin-top: 10px;
}

.info-label {
  margin-top: 28px;
  font-size: 28px;
  color: rgb(114, 112, 112);
}

.info-value {
  font-size: 20px;
  font-weight: bold;
}

/* 큰 텍스트 */
.large-text {
  font-size: 32px;
  font-weight: bold;
  color: #3eb2d5;
}

/* 비행 교육 진행 현황 */
.progress-container {
  margin-top: 30px;
  padding: 20px;
  text-align: center;
  
}

.progress-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.progress-item {
  width: 80%;
  margin: 0 auto;
}

.progress-label {
  margin-top: 15px;
  font-size: 20px;
  margin-bottom: 5px;
}

.progress-bar {
  width: 100%;
  height: 32px;
  background: #e0e0e0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.progress-fill {
  height: 100%;
  background: #007bff;
  width: 0%;
  transition: width 0.5s ease-in-out;
}

.progress-rate {
  font-size: 18px;
  margin-top: 5px;
  font-weight: bold;
}
/* 📌 반응형 스타일 추가 */
/* 768px 이하 (태블릿) */
@media (max-width: 768px) {
  .profile-icon {
    width: 150px;
    height: 150px;
  }
  .username {
    font-size: 30px;
    font-weight: bold;
  }
  .userrole {
    font-size: 24px;   
  }
  .info-label {
    font-size: 22px;
  }
  .info-value {
    font-size: 26px;
  }
  .large-text {
    font-size: 30px;
  }
  .progress-container {
    width: 95%;
  }
  .progress-label {
    font-size: 20px;
  }
  .progress-rate {
    font-size: 18px;
  }
}

/* 480px 이하 (스마트폰) */
/* @media (max-width: 480px) {
  .profile-icon {
    width: 150px;
    height: 150px;
  }
  .username {
    font-size: 30px;
    font-weight: bold;
  }
  .userrole {
    font-size: 24px;   
  }
  .info-label {
    font-size: 22px;
  }
  .info-value {
    font-size: 26px;
  }
  .large-text {
    font-size: 30px;
  }
  .progress-container {
    width: 95%;
  }
  .progress-label {
    font-size: 20px;
  }
  .progress-rate {
    font-size: 18px;
  }
} */
</style>